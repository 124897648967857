import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import "firebase/functions";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: process.env.apiKey,
  // authDomain: process.env.authDomain,
  // projectId: process.env.projectId,
  // storageBucket: process.env.storageBucket,
  // messagingSenderId: process.env.messagingSenderId,
  // appId: process.env.appId,
  // measurementId: process.env.measurementId,
  apiKey: "AIzaSyDyx9wLnFBp2kf-cv_7XlqTn7vHHvtrJJ4",
  authDomain: "consulting-app-e1463.firebaseapp.com",
  projectId: "consulting-app-e1463",
  storageBucket: "consulting-app-e1463.appspot.com",
  messagingSenderId: "962519812161",
  appId: "1:962519812161:web:41680f42e2af114db8dec0",
  measurementId: "G-Y0F01DT8JJ",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebaseApp.auth();
const storage = firebaseApp.storage();
const functions = firebaseApp.functions();

export { db, auth, storage, functions };
