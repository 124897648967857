import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  FormControl,
  Heading,
  Input,
  Text,
  Link,
  Select,
  Stack,
  Textarea,
  HStack,
  Spacer,
  Tag,
  Spinner,
  useToast,
  WrapItem,
  Wrap,
  Avatar,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { db, functions } from "../../config/firebase";

import { FaLinkedin } from "react-icons/fa";
import { IconContext } from "react-icons";
import firebase from "firebase";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { useHistory } from "react-router-dom";
import { timeOptions } from "../../constants/constants";
import { loadStripe } from "@stripe/stripe-js";

function Profile(props) {
  const username = props.match.params.username;

  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");

  const [hideBookButton, setHideBookButton] = useState(false);
  const [hideAvailabilty, setHideAvailabilty] = useState(true);

  const [selectedDay, setSelectedDay] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [disabledDays, setDisabledDays] = useState([]);
  const [userId, setUserId] = useState(null);
  const [bookingCompleted, setBookingCompleted] = useState(false);
  const [bookingID, setBookingID] = useState(null);
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [availabilityTimeSlots, setAvailabilityTimeSlots] = useState([]);
  const [isCheckoutInitaited, setIsCheckoutInitaited] = useState(false);
  const [isDurationDispalyHidden, setIsDurationDispalyHidden] = useState(true);
  const [userBookings, setUserBookings] = useState([]);
  const history = useHistory();
  // New react date-picker implementation
  const datePickerModifiers = {
    disabled: {
      past: {
        before: new Date(),
      },
      daysOfWeek: disabledDays,
    },
    selected: selectedDay,
  };
  const datePickerModifiersStyles = {
    selected: {
      color: "white",
      backgroundColor: "#f25757",
    },
  };

  const getUserAvailability = (startTime, endTime, day) => {
    const placeholderAvailabilityTimeSlots = [];
    let start = false,
      end = false;
    do {
      for (const timeSlot in timeOptions) {
        if (timeOptions[timeSlot] === startTime || start === true) {
          start = true;
          placeholderAvailabilityTimeSlots.push(timeOptions[timeSlot]);
          // Since the start is set to True this top if statement will always trigger,
          // so the below if statement makes it stop when it hits the end of the timeslots.
          if (timeOptions[timeSlot] === endTime) {
            end = true;
            break;
          }
        }
      }
      // It will give us all the slot for user between given interval
    } while (!start || !end);

    // Get all the bookings on selected days for user
    const alreadyBooked = userBookings.filter((booking) => {
      if (booking.bookingDay) {
        const bookingDateTime = booking.bookingDay.toDate();
        const bookingDate = bookingDateTime.getDate();
        const bookingYear = bookingDateTime.getFullYear();
        const bookingMonth = bookingDateTime.getMonth();

        const selectedDate = day.getDate();
        const selectedYear = day.getFullYear();
        const selectedMonth = day.getMonth();

        return (
          bookingDate === selectedDate &&
          bookingMonth === selectedMonth &&
          bookingYear === selectedYear
        );
      } else {
        return false;
      }
    });
    return placeholderAvailabilityTimeSlots.filter(
      (slot) => !alreadyBooked.find((item) => item.bookingTime === slot)
    );
  };

  const handleDayClick = (day, { selected, disabled }) => {
    if (disabled) {
      // Day is disabled, do nothing
      return;
    }
    if (selected) {
      // Unselect the day if already selected
      setSelectedDay(undefined);

      return;
    }
    setSelectedDay(day);

    const selectedAvailableDay = user?.availability ? user.availability[daysOfWeek[day.getDay()]] : undefined
    const selectedDayAvailabilityStart = selectedAvailableDay ? selectedAvailableDay["start"] : undefined;
    const selectedDayAvailabilityEnd = selectedAvailableDay ? selectedAvailableDay["end"] : undefined
    // Setting user availability excluding already booked
    if (selectedDayAvailabilityStart && selectedDayAvailabilityEnd) {
      setAvailabilityTimeSlots(
        getUserAvailability(
          selectedDayAvailabilityStart,
          selectedDayAvailabilityEnd,
          day
        )
      );
    }
  };

  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier.toLowerCase() === "pm") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  };

  const handleBookingClick = () => {
    for (const day in user.availability) {
      let newArray = disabledDays;

      if (user["availability"][day]["isActive"] === false) {
        if (day === "Mon") {
          newArray.push(1);
          setDisabledDays(newArray);
        } else if (day === "Tue") {
          newArray.push(2);
          setDisabledDays(newArray);
        } else if (day === "Wed") {
          newArray.push(3);
          setDisabledDays(newArray);
        } else if (day === "Thu") {
          newArray.push(4);
          setDisabledDays(newArray);
        } else if (day === "Fri") {
          newArray.push(5);
          setDisabledDays(newArray);
        } else if (day === "Sat") {
          newArray.push(6);
          setDisabledDays(newArray);
        } else if (day === "Sun") {
          newArray.push(0);
          setDisabledDays(newArray);
        }
      }
    }
  };

  const stripePromise = loadStripe(
    "pk_live_fnriEeyGBNFYrpXVwQaNEDDd00H5na2MZe"
  );
  const toast = useToast();

  const handleStripeSubmit = async (event) => {
    try {
      // Get Stripe.js instance
      const stripe = await stripePromise;

      // Create booking content in db
      const bookingDBEntry = await handleBookingConfirmation();

      // return;
      const getStripeCheckoutSession = functions.httpsCallable(
        "createStripeCheckoutSession"
      );
      // Convert the hourly rate into an int 2000 for stripe means $20.00
      let amountStringValue = parseInt(
        user.hourlyRate.replace("characterToReplace", "") + "00"
      );
      const orderArray = {
        product: `Time With ${user.displayName}`,
        amount: amountStringValue / 2, //Since rate is hourly, this divides it into 30 min increments
        bookingID: bookingDBEntry,
      };

      const response = await getStripeCheckoutSession(orderArray);

      // When the customer clicks on the button, redirect them to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: response.data.id,
      });

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        toast({
          title: "Error Completing Booking",
          description: result.error.message,
          position: "top",
          status: "error",
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleBookingConfirmation = async (event) => {
    try {
      let isOrderCompleted = false;
      const orderCompletionCheck = user.hourlyRate === undefined || +user.hourlyRate === 0
      if (orderCompletionCheck) {
        isOrderCompleted = true;
      } else {
        isOrderCompleted = false;
      }
      if (!(name && email && message)) {
        throw Error("Fill all required fields!");
      }
      const [hours, minutes] = convertTime12to24(selectedTime).split(":");
      const bookingTime = new Date(selectedDay.setHours(+hours, +minutes));
      console.log("Booking Day Time: ", bookingTime);

      const booking = await db.collection("bookings").add({
        uid: userId,
        name: name,
        email: email,
        message: message,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        bookingTime: selectedTime,
        bookingDay: bookingTime,
        consultantEmail: user.email,
        consultantName: user.displayName,
        consultantMeetingLink: user.zoomLink,
        hourlyRate: user.hourlyRate,
        isCompleted: isOrderCompleted,
      });

      setBookingCompleted(true);
      if (orderCompletionCheck) {
        history.push("/thanks");
      }
      // Get the booking id so that when there is a redirect to stripe checkout we can use this as a param to update the booking
      setBookingID(booking.id);
      return booking.id;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    const getUser = async () => {
      const existingUsername = await db
        .collection("users")
        .where("username", "==", username.toLowerCase())
        .get();
      existingUsername.forEach((doc) => {
        setUser(doc.data());
        setUserId(doc.id);

        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        if (query.get("success")) {
          setMessage("Order placed! You will receive an email confirmation.");
        }
        if (query.get("canceled")) {
          setMessage(
            "Order canceled -- continue to shop around and checkout when you're ready."
          );
        }
      });
    };
    getUser();
  }, []);

  useEffect(() => {
    const getUserBookings = async () => {
      const bookings = await db
        .collection("bookings")
        .where("uid", "==", userId)
        .get();
      let tempBookings = [];
      for (const doc of bookings.docs) {
        tempBookings.push(doc.data());
      }
      console.log("Total Bookings: ", tempBookings.length);
      setUserBookings(tempBookings);
    };
    getUserBookings();
  }, [userId]);

  // const handleBooking

  if (!user) {
    return (
      <Flex align="center" justify="center" height={window.innerHeight - 64}>
        <Spinner size="xl" color="brandLight.800" />
      </Flex>
    );
  } else if (user) {
    return (
      <Flex align="center" justify="center" mb="100px">
        <Container px={6}>
          <Box pt={8} pb={4}>
            <Center>
              <Avatar
                size="2xl"
                name={user.displayName}
                src={user.profileImageURL}
                alt={`${user?.displayName || ''} profile image`}
                borderRadius="full"
                boxSize="150px"
              />
            </Center>
          </Box>
          <Box pb={6}>
            <Center>
              <Heading as="h3" size="md">
                {user.displayName}
              </Heading>
            </Center>
          </Box>
          <Box overflow="hidden" p={6}>
            <Stack spacing={8}>
              <Wrap justify="center">
                {user.areasOfExpertise ? (
                  user.areasOfExpertise.map((expertise, index) => (
                    <WrapItem key={index}>
                      <Tag variant="outline" color={"gray.800"} bg={"red.100"}>
                        {expertise.value}
                      </Tag>
                    </WrapItem>
                  ))
                ) : (
                  <div></div>
                )}
              </Wrap>

              <div style={{ whiteSpace: "pre-line" }}>
                <Text align="left" fontSize="15px">
                  {user.bio}
                </Text>
              </div>

              <Flex>
                <Spacer />

                <Text color="gray.500" as="b">
                  {user.hourlyRate === 0
                    ? "Free"
                    : `$${parseInt(user.hourlyRate)}/hr`}
                </Text>
                <Spacer />

                <IconContext.Provider
                  value={{
                    color: "#2867B2",
                    size: "1.5em",
                    className: "global-class-name",
                  }}
                >
                  <div>
                    <HStack>
                      <Link href={user.linkedInLink} isExternal>
                        <FaLinkedin />
                      </Link>
                      <Text color="green.400">Verified</Text>
                    </HStack>
                  </div>
                </IconContext.Provider>

                <Spacer />
              </Flex>
            </Stack>
            <Box pt="40px" hidden={hideBookButton}>
              <Center>
                <Button
                  type="submit"
                  width="200px"
                  colorScheme="red"
                  onClick={(e) => {
                    setHideBookButton(true);
                    setHideAvailabilty(false);
                    handleBookingClick();
                  }}
                >
                  Book now
                </Button>{" "}
              </Center>
            </Box>
            <Box pt="40px" hidden={hideAvailabilty}>
              <Center>
                <Stack spacing={4}>
                  <DayPicker
                    showOutsideDays
                    modifiers={datePickerModifiers}
                    modifiersStyles={datePickerModifiersStyles}
                    onDayClick={(e, modifiers) => {
                      handleDayClick(e, modifiers);
                      setIsDurationDispalyHidden(true);
                    }}
                  />
                  <HStack>
                    <Select
                      placeholder="Select time"
                      onChange={(e) => {
                        setSelectedTime(e.target.value);
                        setIsDurationDispalyHidden(false);
                      }}
                    >
                      {availabilityTimeSlots.map((time, index) => {
                        return (
                          <option key={time} value={time}>
                            {time}
                          </option>
                        );
                      })}
                    </Select>
                    <Text fontSize="sm" hidden={isDurationDispalyHidden}>
                      30 min duration
                    </Text>
                  </HStack>

                  <FormControl isRequired>
                    <Input
                      type="name"
                      autoComplete="name"
                      placeholder="Your name"
                      onChange={(e) => setName(e.currentTarget.value)}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <Input
                      type="email"
                      autoComplete="email"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <Textarea
                      type="text"
                      placeholder="Summarize what you're looking for"
                      autoComplete="off"
                      onChange={(e) => setMessage(e.currentTarget.value)}
                    />
                  </FormControl>
                  {/* Depending of the price is 0 or not, then show the appropriate button */}
                  {user.hourlyRate == 0 || user.hourlyRate == null ? (
                    <Button
                      type="submit"
                      colorScheme="red"
                      disabled={!(name && email && message)}
                      onClick={(e) => {
                        handleBookingConfirmation(e);
                      }}
                    >
                      Book Time
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      colorScheme="red"
                      id="stripe-checkout"
                      disabled={!(name && email && message)}
                      isLoading={isCheckoutInitaited}
                      onClick={(e) => {
                        setIsCheckoutInitaited(true);
                        handleStripeSubmit(e);
                      }}
                    >
                      Checkout
                    </Button>
                  )}
                </Stack>
              </Center>
            </Box>
          </Box>
        </Container>
      </Flex>
    );
  }
}

export default Profile;
