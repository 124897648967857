import { Avatar, Button, Flex, Link, Stack } from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { auth } from "../../config/firebase";
import { logOut, selectUser } from "../../features/userSlice";
import "./NavOptions.css";

function NavOptions() {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(logOut());
    auth.signOut();
    // console.log("all parts completed now about ot redired");
    history.push("/");
  };
  if (user) {
    return (
      <Flex alignItems="center">
        <Stack spacing={5} direction="row">
          <Button
            fontSize="sm"
            fontWeight="bold"
            color="black"
            onClick={handleLogout}
            variant="link"
          >
            Log out
          </Button>

          <Link href="/account">
            <Avatar
              size="sm"
              bg="brandLight.800"
              color="white"
              name={user.displayName}
              src="https://media-exp1.licdn.com/dms/image/C5603AQF9lQm_KMoIiQ/profile-displayphotadf1OCS7l8M_Xb7KtY"
            />
          </Link>
        </Stack>
      </Flex>
    );
  } else {
    return (
      <Flex alignItems="center" fontSize="sm" fontWeight="bold">
        <Stack spacing={5} direction="row">
          <Link color="brandLight.800" href="/signup">
            Sign up
          </Link>
          <Link href="/login">Log in</Link>
        </Stack>
      </Flex>
    );
  }
}

export default NavOptions;
