// import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import Account from "./components/accounts/Account";
import LogIn from "./components/auth/LogIn";
import SignUp from "./components/auth/SignUp";
import Navbar from "./components/navigation/Nav";
import Homepage from "./components/Homepage/Homepage";
import HomepageMentee from "./components/Homepage/HomepageMentee";
import Homepage2 from "./components/Homepage-copy";

import { logIn, logOut, selectUser } from "./features/userSlice";
import { useEffect } from "react";
import { auth, db } from "./config/firebase";
import Footer from "./components/navigation/Footer";
import Profile from "./components/accounts/Profile";
import Checkout from "./components/Checkout";
import ThankYou from "./components/ThankYou";

import { Flex, Spinner } from "@chakra-ui/react";
import OrderCancelled from "./components/OrderCancelled";

const PrivateRoute = ({ children, ...remainingProps }) => {
  const auth = useSelector(selectUser);
  return (
    <Route
      {...remainingProps}
      render={({ location }) =>
        !auth && auth === null ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

function AuthIsLoaded({ children }) {
  const user = useSelector(selectUser);

  if (!user) {
    return (
      <Flex align="center" justify="center" height={window.innerHeight - 64}>
        <Spinner size="xl" color="brandLight.800" />
      </Flex>
    );
  } else {
    return children;
  }

  // if (user === undefined) {
  //   return (
  //     <Flex align="center" justify="center" height={window.innerHeight - 64}>
  //       <Spinner size="xl" color="brandLight.800" />
  //     </Flex>
  //   );
  // } else if (user) {
  //   return children;
  // } else {
  //   return <Redirect to="/login" />;
  // }

  // {
  //   !user ? (
  //     <Flex align="center" justify="center" height={window.innerHeight - 64}>
  //       <Spinner size="xl" color="brandLight.800" />
  //     </Flex>
  //   ) : (
  //     children
  //   );
  // }
  // if (!user) {
  //   return <Redirect to="/login" />;
  // } else {
  //   return children;
  // }
}

function App() {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(logOut());
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userDetails = await db
          .collection("users")
          .doc(userAuth.uid)
          .get();

        const userData = userDetails.data();
        // console.log("userAuth", userData);

        dispatch(
          logIn({
            uid: userAuth.uid,
            displayName: userData.displayName,
            email: userData.email,
            firstName: userData.firstName,
            lastName: userData.lastName,
            initials: userData.initials,
            bio: userData.bio,
            zoomLink: userData.zoomLink,
            hourlyRate: userData.hourlyRate,
            availability: userData.availability,
            profileImageURL: userData.profileImageURL,
            linkedInLink: userData.linkedInLink,
            userName: userData.username,
            areasOfExpertise: userData.areasOfExpertise,
          })
        );
      } else {
        dispatch(logOut());
      }
    });
  }, []);
  return (
    <BrowserRouter>
      <div className="app">
        <Navbar />
        <div className="content">
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route path="/mentee" component={HomepageMentee} />
            <Route path="/homepage2" component={Homepage2} />
            <Route path="/login" component={LogIn} />
            <Route path="/thanks" component={ThankYou} />
            <Route path="/order-cancelled" component={OrderCancelled} />
            <Route path="/signup" component={SignUp} />
            <Route path="/u/:username" component={Profile} />
            <Route path="/checkout" component={Checkout} />
            <AuthIsLoaded>
              <Route path="/account" component={Account} />
            </AuthIsLoaded>
          </Switch>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;

{
  /* <Route path="/profile/:id" component={Profile} /> */
}
