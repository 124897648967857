import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import LogoBanner from "./LogoBanner";
import HowItWorksMentee from "./HowItWorksMentee";
import ProfileShowcase from "./ProfileShowcase";

export default function Homepage() {
  return (
    // <Center px="10" pt="40px">
    <Flex
      // minHeight="80vh"
      // height={window.innerHeight - 64}
      direction="column"
      alignItems="center"
      justifyContent="center"
      // bgGradient="linear(to-l, #7928CA, #FF0080)"
    >
      <Container>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Heading
            fontWeight={700}
            fontSize={{ base: "6xl", sm: "6xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            {/* 1 on 1 meetings with the people that can change your life */}
            {/* 1 on 1 meetings with the people you look up to */}
            Direct answers to your direct questions
            {/* The site for "let me pick your brain" */}
            <br />
            {/* <Text as={"span"} color={"brandLight.800"}>
              "let me pick your brain"{" "}
            </Text> */}
          </Heading>
          <Text
            fontSize={{ base: "18px", sm: "16px", md: "18px" }}
            color={"gray.600"}
          >
            {/* Get direct answers to your direct questions */}
            {/* to get personalized feedback and answers to your questions sessions. from experts direct answers to
            your direct questions, from experts. for your direct questions. dGet
            paid to share your personal insights & experiences.  */}
            {/* <br /> */}
            {/* Get paid to share your personal insights & experiences. Career
            consulting, resume reviews, grad school applications & interview
            prep. */}
            Get personalized feedback & advice on career moves, resumes, grad
            school, interview prep & all things career related from people that
            have done it already
          </Text>
          <Stack
            direction={{ base: "column", sm: "column", md: "row" }}
            spacing={3}
            align={"center"}
            alignSelf={"center"}
            position={"relative"}
          >
            <Button
              as="a"
              href="#mentor-showcase"
              size="lg"
              colorScheme={"green"}
              bg={"brandLight.800"}
              px={6}
              _hover={{
                bg: "brandLight.900",
              }}
            >
              Get Answers Now
            </Button>
            {/* <Text
              fontSize={{ base: "10px", sm: "13px", md: "13px" }}
              color={"gray.600"}
            >
              *no signup required
            </Text> */}

            {/* <Button
              as="a"
              href="#mentor-showcase"
              size="lg"
              variant="outline"
              color={"brandLight.800"}
              borderColor={"gray.400"}
              // colorScheme={"green"}
              // bg={"brandLight.800"}
              px={6}
              _hover={{
                bg: "red.100",
                borderColor: "brandLight.900",
              }}
            >
              Book Time Now
            </Button> */}
            {/* <Box>
              <Icon
                as={Arrow}
                color={useColorModeValue("gray.800", "gray.300")}
                w={71}
                position={"absolute"}
                right={-71}
                top={"10px"}
              />
              <Text
                fontSize={"lg"}
                fontFamily={"Caveat"}
                position={"absolute"}
                right={"-125px"}
                top={"-15px"}
                transform={"rotate(10deg)"}
              >
                Starting at $15/mo
              </Text>
            </Box> */}
          </Stack>
        </Stack>
      </Container>
      <LogoBanner caption="Experts From" />
      <HowItWorksMentee />
      <ProfileShowcase caption="Choose A Mentor" />

      {/* <Box maxW="32rem" p="10px" textAlign="center">
        <Heading size="2xl" mb={4}>
          The app for "let me pick your brain"
        </Heading>
        <Text fontSize="22px">
          Get paid to share your personal insights & experiences
        </Text>
        <Box>
          <a href="/signup">
            <Button
              size="lg"
              // bgGradient="linear(to-l, #7928CA, #FF0080)"
              bg="brandLight.800"
              color="white"
              mt="24px"
            >
              Sign Up Now
            </Button>
          </a>
        </Box>
      </Box> */}
    </Flex>

    // </Center>
  );
}
