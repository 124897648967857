import {
  Box,
  Center,
  Heading,
  Image,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React from "react";
import Microsoft from "../../img/Microsoft-gray.png";
import Google from "../../img/Google-gray.png";
import McKinsey from "../../img/McKinsey.png";
import McMaster from "../../img/McMaster-gray.png";
import UfT from "../../img/UfT-gray.png";
import Wharton from "../../img/Wharton-gray.png";

function LogoBanner(props) {
  const { caption } = props;
  return (
    <Box>
      <Text pb="15px" fontWeight="300" color="gray.500" fontSize="md">
        {caption}
      </Text>
      {/* <Heading
        as="h2"
        color="gray.500"
        fontSize={{ base: "2xl", sm: "2xl", md: "2xl" }}
        pb="30px"
      >
        You're In Good Company
      </Heading> */}
      <Box
        // bg="gray.100"
        p="40px"
      >
        <SimpleGrid
          columns={{ base: 1, md: 6 }}
          minChildWidth="120px"
          spacing="40px"
        >
          <Center>
            <Image
              src={Google}
              //   src="https://download.logo.wine/logo/Microsoft/Microsoft-Logo.wine.png"
              alt="Google Logo"
            />
          </Center>
          <Center>
            <Image
              src={Wharton}
              //   src="https://download.logo.wine/logo/McKinsey_%26_Company/McKinsey_%26_Company-Logo.wine.png"
              alt="Wharton Logo"
            />
          </Center>

          <Center>
            <Image
              src={Microsoft}
              //   src="https://download.logo.wine/logo/University_of_Toronto/University_of_Toronto-Logo.wine.png"
              alt="Microsoft Logo"
            />
          </Center>

          <Center>
            <Image
              src={UfT}
              //   src="https://download.logo.wine/logo/Google/Google-Logo.wine.png"
              alt="University Of Toronto Logo"
            />
          </Center>
          <Center>
            <Image
              src={McKinsey}
              //   src="https://download.logo.wine/logo/Google/Google-Logo.wine.png"
              alt="McKinsey & Company Logo"
            />
          </Center>
          <Center>
            <Image
              src={McMaster}
              //   src="https://download.logo.wine/logo/Google/Google-Logo.wine.png"
              alt="McMaster Logo"
            />
          </Center>
        </SimpleGrid>
      </Box>
      {/* <Text pt="15px" fontWeight="300" color="gray.500" fontSize="md">
        You're In Good Company
      </Text> */}
    </Box>
  );
}

export default LogoBanner;
