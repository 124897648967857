import {
  Heading,
  Avatar,
  Box,
  Center,
  Text,
  Stack,
  Button,
  Link,
  Badge,
  useColorModeValue,
  WrapItem,
  Wrap,
  Tag,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

export default function ProfileCard(props) {
  const {
    displayName,
    hourlyRate,
    profileURL,
    profileImageURL,
    bio,
    areasOfExpertise,
  } = props;

  // console.log("displayname", displayName);

  const expertiseAdded = (props) => {
    if (areasOfExpertise) {
      return areasOfExpertise.map((expertise) => {
        console.log("Mapping expertise ", expertise);
        return (
          <Badge
            px={2}
            py={1}
            color={"gray.800"}
            bg={"gray.50"}
            fontWeight={"400"}
          >
            {expertise}
          </Badge>
        );
      });
    }
  };

  return (
    <Center py={4}>
      <Box
        maxW={"320px"}
        minH={"420px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.900")}
        boxShadow={"2xl"}
        rounded={"lg"}
        p={6}
        textAlign={"center"}
      >
        <Avatar
          size={"xl"}
          src={profileImageURL}
          alt={"Avatar Alt"}
          mb={4}
          pos={"relative"}
          _after={{
            content: '""',
            w: 4,
            h: 4,
            bg: "green.300",
            border: "2px solid white",
            rounded: "full",
            pos: "absolute",
            bottom: 0,
            right: 3,
          }}
        />
        <Heading fontSize={"2xl"} fontFamily={"body"}>
          {displayName}
        </Heading>
        <Text fontWeight={600} color={"gray.500"} mb={4}>
          {Number(hourlyRate) > 0 ? `${hourlyRate}/hr` : "Free"}
        </Text>
        <div style={{ whiteSpace: "pre-line" }}>
          <Text
            textAlign={"left"}
            fontSize="sm"
            color={useColorModeValue("gray.700", "gray.400")}
            px={3}
            // maxWidth="123ch"
            // text-overflow="ellipsis"
            // white-space="nowrap"
            // isTruncated={bio.length > 100 ? true : false}
          >
            {bio.substring(0, 80)}...
            {/* Actress, musician, songwriter and artist. PM for work inquires or */}
          </Text>
        </div>

        {/* <Stack align={"center"} justify={"center"} direction={"row"} mt={6}> */}
        <Wrap pt={4}>
          {areasOfExpertise.map((expertise, index) => {
            return (
              <WrapItem key={index}>
                {/* <Badge
                  px={2}
                  py={1}
                  color={"gray.800"}
                  bg={"gray.50"}
                  fontWeight={"400"}
                >
                  {expertise}
                </Badge> */}
                <Tag
                  variant="outline"
                  // outlineColor={"red.800"}
                  color={"gray.800"}
                  bg={"red.100"}
                  fontSize={"xs"}
                >
                  {expertise}
                </Tag>
              </WrapItem>
            );
          })}
        </Wrap>
        {/* {expertiseAdded} */}
        {/* {areasOfExpertise.map((expertise) => {
            return (
              <Badge
                px={2}
                py={1}
                color={"gray.800"}
                bg={"gray.50"}
                fontWeight={"400"}
              >
                {expertise}
              </Badge>
            );
          })} */}
        {/* <Badge
            px={2}
            py={1}
            bg={useColorModeValue("gray.50", "gray.800")}
            fontWeight={"400"}
          >
            #art
          </Badge>
          <Badge
            px={2}
            py={1}
            bg={useColorModeValue("gray.50", "gray.800")}
            fontWeight={"400"}
          >
            #photography
          </Badge>
          <Badge
            px={2}
            py={1}
            bg={useColorModeValue("gray.50", "gray.800")}
            fontWeight={"400"}
          >
            #music
          </Badge> */}
        {/* </Stack> */}

        <Stack mt={8} direction={"row"} spacing={4}>
          {/* <Button
            flex={1}
            fontSize={"sm"}
            rounded={"full"}
            _focus={{
              bg: "gray.200",
            }}
          >
            Message
          </Button> */}
          <Button
            // position="absolute"
            // bottom={0}
            as="a"
            href={profileURL}
            flex={1}
            fontSize={"sm"}
            // rounded={"full"}
            bg={"brandLight.800"}
            color={"white"}
            boxShadow={
              "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
            }
            _hover={{
              bg: "brandLight.900",
            }}
            _focus={{
              bg: "brandLight.900",
            }}
          >
            View Profile{" "}
          </Button>
        </Stack>
      </Box>
    </Center>
  );
}
