// const timeOptions = [];
// const hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

// function getTimes() {
//   const amPM = ["am", "pm"];
//   const minutes = ["00", "30"];
//   let counter = 0;
//   for (let day = 0; day < amPM.length; day++) {
//     const dayOrNight = amPM[day];
//     console.log(dayOrNight);

//     for (const hour in hours) {
//       console.log(hour);
//       for (let index = 0; index < minutes.length; index++) {
//         const minute = minutes[index];
//         counter++;
//         const finalTime = hours[hour] + ":" + minute + " " + dayOrNight;
//         // const objectToAdd = { key: counter, text: finalTime, value: counter };
//         const objectToAdd = finalTime;
//         timeOptions.push(objectToAdd);
//       }
//     }
//   }
// }

// getTimes();
// console.log(timeOptions);

export const expertiseOptions = [];

// export const expertiseOptions = [
//   { value: "Engineer", label: "Engineer" },
//   { value: "Lawyer", label: "Lawyer" },
//   { value: "Doctor", label: "Doctor" },
//   { value: "MBA", label: "MBA" },
//   { value: "Recruiter", label: "Recruiter" },
//   { value: "Consulting", label: "Consulting" },
//   { value: "LSAT", label: "LSAT" },
//   { value: "GMAT", label: "GMAT" },
//   { value: "MCAT", label: "MCAT" },
// ];
export const timeOptions = [
  "12:00 am",
  "12:30 am",
  "1:00 am",
  "1:30 am",
  "2:00 am",
  "2:30 am",
  "3:00 am",
  "3:30 am",
  "4:00 am",
  "4:30 am",
  "5:00 am",
  "5:30 am",
  "6:00 am",
  "6:30 am",
  "7:00 am",
  "7:30 am",
  "8:00 am",
  "8:30 am",
  "9:00 am",
  "9:30 am",
  "10:00 am",
  "10:30 am",
  "11:00 am",
  "11:30 am",
  "12:00 pm",
  "12:30 pm",
  "1:00 pm",
  "1:30 pm",
  "2:00 pm",
  "2:30 pm",
  "3:00 pm",
  "3:30 pm",
  "4:00 pm",
  "4:30 pm",
  "5:00 pm",
  "5:30 pm",
  "6:00 pm",
  "6:30 pm",
  "7:00 pm",
  "7:30 pm",
  "8:00 pm",
  "8:30 pm",
  "9:00 pm",
  "9:30 pm",
  "10:00 pm",
  "10:30 pm",
  "11:00 pm",
  "11:30 pm",
];
// export const timeOptions = [
//   { key: 0, text: "", value: 0 },
//   { key: 1, text: "12:00 am", value: 1 },
//   { key: 2, text: "12:15 am", value: 2 },
//   { key: 3, text: "12:30 am", value: 3 },
//   { key: 4, text: "12:45 am", value: 4 },
//   { key: 5, text: "1:00 am", value: 5 },
//   { key: 6, text: "1:15 am", value: 6 },
//   { key: 7, text: "1:30 am", value: 7 },
//   { key: 8, text: "1:45 am", value: 8 },
//   { key: 9, text: "2:00 am", value: 9 },
//   { key: 10, text: "2:15 am", value: 10 },
//   { key: 11, text: "2:30 am", value: 11 },
//   { key: 12, text: "2:45 am", value: 12 },
//   { key: 13, text: "3:00 am", value: 13 },
//   { key: 14, text: "3:15 am", value: 14 },
//   { key: 15, text: "3:30 am", value: 15 },
//   { key: 16, text: "3:45 am", value: 16 },
//   { key: 17, text: "4:00 am", value: 17 },
//   { key: 18, text: "4:15 am", value: 18 },
//   { key: 19, text: "4:30 am", value: 19 },
//   { key: 20, text: "4:45 am", value: 20 },
//   { key: 21, text: "5:00 am", value: 21 },
//   { key: 22, text: "5:15 am", value: 22 },
//   { key: 23, text: "5:30 am", value: 23 },
//   { key: 24, text: "5:45 am", value: 24 },
//   { key: 25, text: "6:00 am", value: 25 },
//   { key: 26, text: "6:15 am", value: 26 },
//   { key: 27, text: "6:30 am", value: 27 },
//   { key: 28, text: "6:45 am", value: 28 },
//   { key: 29, text: "7:00 am", value: 29 },
//   { key: 30, text: "7:15 am", value: 30 },
//   { key: 31, text: "7:30 am", value: 31 },
//   { key: 32, text: "7:45 am", value: 32 },
//   { key: 33, text: "8:00 am", value: 33 },
//   { key: 34, text: "8:15 am", value: 34 },
//   { key: 35, text: "8:30 am", value: 35 },
//   { key: 36, text: "8:45 am", value: 36 },
//   { key: 37, text: "9:00 am", value: 37 },
//   { key: 38, text: "9:15 am", value: 38 },
//   { key: 39, text: "9:30 am", value: 39 },
//   { key: 40, text: "9:45 am", value: 40 },
//   { key: 41, text: "10:00 am", value: 41 },
//   { key: 42, text: "10:15 am", value: 42 },
//   { key: 43, text: "10:30 am", value: 43 },
//   { key: 44, text: "10:45 am", value: 44 },
//   { key: 45, text: "11:00 am", value: 45 },
//   { key: 46, text: "11:15 am", value: 46 },
//   { key: 47, text: "11:30 am", value: 47 },
//   { key: 48, text: "11:45 am", value: 48 },
//   { key: 49, text: "12:00 pm", value: 49 },
//   { key: 50, text: "12:15 pm", value: 50 },
//   { key: 51, text: "12:30 pm", value: 51 },
//   { key: 52, text: "12:45 pm", value: 52 },
//   { key: 53, text: "1:00 pm", value: 53 },
//   { key: 54, text: "1:15 pm", value: 54 },
//   { key: 55, text: "1:30 pm", value: 55 },
//   { key: 56, text: "1:45 pm", value: 56 },
//   { key: 57, text: "2:00 pm", value: 57 },
//   { key: 58, text: "2:15 pm", value: 58 },
//   { key: 59, text: "2:30 pm", value: 59 },
//   { key: 60, text: "2:45 pm", value: 60 },
//   { key: 61, text: "3:00 pm", value: 61 },
//   { key: 62, text: "3:15 pm", value: 62 },
//   { key: 63, text: "3:30 pm", value: 63 },
//   { key: 64, text: "3:45 pm", value: 64 },
//   { key: 65, text: "4:00 pm", value: 65 },
//   { key: 66, text: "4:15 pm", value: 66 },
//   { key: 67, text: "4:30 pm", value: 67 },
//   { key: 68, text: "4:45 pm", value: 68 },
//   { key: 69, text: "5:00 pm", value: 69 },
//   { key: 70, text: "5:15 pm", value: 70 },
//   { key: 71, text: "5:30 pm", value: 71 },
//   { key: 72, text: "5:45 pm", value: 72 },
//   { key: 73, text: "6:00 pm", value: 73 },
//   { key: 74, text: "6:15 pm", value: 74 },
//   { key: 75, text: "6:30 pm", value: 75 },
//   { key: 76, text: "6:45 pm", value: 76 },
//   { key: 77, text: "7:00 pm", value: 77 },
//   { key: 78, text: "7:15 pm", value: 78 },
//   { key: 79, text: "7:30 pm", value: 79 },
//   { key: 80, text: "7:45 pm", value: 80 },
//   { key: 81, text: "8:00 pm", value: 81 },
//   { key: 82, text: "8:15 pm", value: 82 },
//   { key: 83, text: "8:30 pm", value: 83 },
//   { key: 84, text: "8:45 pm", value: 84 },
//   { key: 85, text: "9:00 pm", value: 85 },
//   { key: 86, text: "9:15 pm", value: 86 },
//   { key: 87, text: "9:30 pm", value: 87 },
//   { key: 88, text: "9:45 pm", value: 88 },
//   { key: 89, text: "10:00 pm", value: 89 },
//   { key: 90, text: "10:15 pm", value: 90 },
//   { key: 91, text: "10:30 pm", value: 91 },
//   { key: 92, text: "10:45 pm", value: 92 },
//   { key: 93, text: "11:00 pm", value: 93 },
//   { key: 94, text: "11:15 pm", value: 94 },
//   { key: 95, text: "11:30 pm", value: 95 },
//   { key: 96, text: "11:45 pm", value: 96 },
// ];
