import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Textarea,
  SimpleGrid,
  useToast,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip,
  HStack,
  Switch,
  Avatar,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { db, storage } from "../../config/firebase";
import { selectUser } from "../../features/userSlice";
import { useSelector } from "react-redux";
import { FiCopy, FiCheck, FiEye, FiCamera, } from "react-icons/fi";
import { v4 as uuid } from "uuid";
import firebase from "firebase";
import { timeOptions, expertiseOptions } from "../../constants/constants";
import CreatableSelect from "react-select/creatable";

function Account() {
  const user = useSelector(selectUser);
  const toast = useToast();

  const [profileImage, setProfileImage] = useState(
    user.profileImageURL
      ? { url: user.profileImageURL, file: null }
      : {
          url: "https://firebasestorage.googleapis.com/v0/b/consulting-app-e1463.appspot.com/o/profileImages%2Fplaceholder-pic.png?alt=media&token=6f6947cc-9485-4c85-b6a3-b20b74d830ab",
          file: null,
        }
  );
  const [profileImageUpdated, setProfileImageUpdated] = useState(false);
  const [bio, setBio] = useState(user ? user.bio : "");
  const [zoomLink, setZoomLink] = useState(user ? user.zoomLink : "");
  const [linkedInLink, setLinkedInLink] = useState(
    user ? user.linkedInLink : ""
  );
  const [hourlyRate, setHourlyRate] = useState(user ? user.hourlyRate : 0);
  const [userName, setUserName] = useState(user ? user.userName : "");
  const [profileURL, setProfileURL] = useState(
    user.userName ? "personalinsights.co/u/" + user.userName : ""
  );
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [availabilityUpdated, setAvailabilityUpdated] = useState(false);
  const [areasOfExpertise, setAreasOfExpertise] = useState(
    user.areasOfExpertise ? user.areasOfExpertise : []
  );
  const [availability, setAvailability] = useState(
    user.availability
      ? user.availability
      : {
          Mon: {
            isActive: false,
            start: "",
            end: "",
          },
          Tue: {
            isActive: false,
            start: "",
            end: "",
          },
          Wed: {
            isActive: false,
            start: "",
            end: "",
          },
          Thu: {
            isActive: false,
            start: "",
            end: "",
          },
          Fri: {
            isActive: false,
            start: "",
            end: "",
          },
          Sat: {
            isActive: false,
            start: "",
            end: "",
          },
          Sun: {
            isActive: false,
            start: "",
            end: "",
          },
        }
  );

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const creatableSelectComponents = {
    DropdownIndicator: null,
  };

  const handleImageUpload = async (e) => {
    // Now get files in the FileList object
    const imageFile = e.target.files[0];

    if (imageFile) {
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);

      reader.onloadend = (loadImage) => {
        setProfileImage({ url: loadImage.target.result, file: imageFile });
        setProfileImageUpdated(true);
        setSaveDisabled(false);
      };
    }

    const id = uuid();

    const uploadTask = storage.ref(`profileImages/${id}`).put(imageFile);

    console.log(uploadTask);

    uploadTask.on(
      "state_changed",
      null,
      (error) => {
        // Handle unsuccessful uploads
        toast({
          title: "Update Error",
          description: error.message,
          position: "top",
          status: "error",
          isClosable: true,
        });
      },
      async () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        const imgURL = await uploadTask.snapshot.ref.getDownloadURL();

        // console.log(user.uid, imgURL);

        db.collection("users").doc(user.uid).update({
          profileImageURL: imgURL,
          profileUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }
    );
  };

  // Handle Time Changes
  const handleDateSelection = (event, dayOfWeek) => {
    setSaveDisabled(false);
    setAvailabilityUpdated(true);
    setAvailability({
      ...availability,
      [dayOfWeek]: {
        ...availability[dayOfWeek],
        isActive: event,
      },
    });
  };

  const handleTimeSeleciton = (event, dayOfWeek, position) => {
    // console.log(event.label);
    setSaveDisabled(false);
    setAvailabilityUpdated(true);
    setAvailability({
      ...availability,
      [dayOfWeek]: {
        ...availability[dayOfWeek],
        [position]: event.value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await db.collection("users").doc(user.uid).update({
        zoomLink: zoomLink,
        bio: bio,
        hourlyRate: hourlyRate,
        linkedInLink: linkedInLink,
        profileUpdatedAt: new Date(),
        username: userName,
        profileURL: profileURL,
        areasOfExpertise: areasOfExpertise,
      });

      if (availabilityUpdated === true) {
        // console.log("availability fired");

        await db.collection("users").doc(user.uid).update({
          availability: availability,
        });
      }

      toast({
        title: "Account updated!",
        description: "You're ready to share your link now",
        position: "top",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Update Error",
        description: error.message,
        position: "top",
        status: "error",
        isClosable: true,
      });
    }
  };

  const handleAreasOfExpertiseChange = (newValue, actionMeta) => {
    console.log("newValue", newValue);
    let newArray = [];

    for (let index = 0; index < newValue.length; index++) {
      const element = newValue[index];
      console.log(
        "for look index = ",
        index,
        "element =",
        element,
        "element.value=",
        element.value
      );
      newArray.push({ value: element.value, label: element.value });

      console.log("newArray = ", newArray);
    }
    setAreasOfExpertise(newArray);
    setSaveDisabled(false);
  };

  // Prevent users from adding spaces in their username
  const handleSpaceKeyPress = (e) => {
    if (e.code === "Space") {
      console.log("space clicked");
      e.preventDefault();
    }
  };

  useEffect(() => {
    // After user has been loaded decide whether to propose a custom profile name
    const loadUserName = async () => {
      if (!user.userName) {
        // Remove all special charachters (but not special letters like ë) and lowercase
        const proposedUserName = (user.firstName + user.lastName)
          .replaceAll(/[^A-Z0-9]+/gi, "")
          .toLowerCase();
        const snapshot = await db
          .collection("users")
          .where("username", "==", proposedUserName)
          .get();
        if (snapshot.empty) {
          console.log("No matching documents.");
          setUserName(proposedUserName);
          setSaveDisabled(false);
          setProfileURL("personalinsights.co/u/" + proposedUserName);
          return;
        } else {
          return setUserName("");
        }
      }
    };
    loadUserName();
  }, []);
  return (
    <Flex alignItems="center" justifyContent="center">
      <Container>
        <Box py="40px">
          <Center>
            <Avatar
              className="profile-img-container"
              size="2xl"
              name={user.displayName}
              src={profileImage.url}
              borderRadius="full"
              boxSize="150px"
              boxShadow="xl"
              onClick={() => {
                document.getElementById("image-upload-box").click();
              }}
            >
              <span> 
                <FiCamera className="icon-wrapper"/>
              </span>
            </Avatar>
            <input
              type="file"
              accept="image/*"
              id="image-upload-box"
              style={{
                display: "none",
              }}
              onChange={handleImageUpload}
            />
          </Center>
        </Box>
        <Box pb="10px">
          <Center>
            <Heading pb={4} as="h3" size="md">
              Hi {user.firstName}
            </Heading>
          </Center>
        </Box>
        <Box pb="30px">
          <Center>
            <Stack spacing={2}>
              <Text color="brandLight.800" pr="5px" fontSize="sm">
                {profileURL}
              </Text>
              <Center>
                <Box
                  style={{
                    visibility: profileURL == "" ? "hidden" : "visible",
                  }}
                >
                  <HStack spacing={2}>
                    <Tooltip
                      label="Click to copy"
                      bg="brandLight.800"
                      color="white"
                    >
                      <span>
                        <FiCopy
                          onClick={() => {
                            navigator.clipboard.writeText(profileURL);
                          }}
                        />
                      </span>
                    </Tooltip>
                    <Link
                      onClick={async (e) => {
                        await handleSubmit(e);
                        window.open(`https://${profileURL}`, "_blank");
                      }}
                    >
                      <FiEye />
                    </Link>
                  </HStack>
                </Box>
              </Center>
            </Stack>
          </Center>
        </Box>
        <Tabs isFitted variant="soft-rounded" colorScheme="gray">
          <TabList mb="10px">
            <Tab>Account</Tab>
            <Tab>Availability</Tab>
            {/* <Tab>Bookings</Tab>
            <Tab>Payments</Tab> */}
          </TabList>
          <TabPanels>
            <TabPanel px={2}>
              <Box
                boxShadow="xl"
                rounded="lg"
                bg="white"
                borderWidth="1px"
                borderRadius="lg"
                // borderColor="#A0AEC0"
                overflow="hidden"
                p={6}
              >
                <Stack spacing={4}>
                  <FormControl id="bio" isRequired>
                    <FormLabel>Bio</FormLabel>
                    <Textarea
                      placeholder="Talk about your experiences and areas you can help others here.."
                      fontSize="14px"
                      resize="vertical"
                      onChange={(e) => (
                        setBio(e.target.value), setSaveDisabled(false)
                      )}
                      value={bio}
                    />
                  </FormControl>

                  <FormControl id="linkedIn-link" isRequired>
                    <FormLabel>Areas Of Expertise</FormLabel>
                    <CreatableSelect
                      isMulti
                      name="expertise"
                      value={areasOfExpertise}
                      options={expertiseOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleAreasOfExpertiseChange}
                      components={creatableSelectComponents}
                      placeholder="Type something and press enter..."
                    />
                  </FormControl>
                  <FormControl id="meeting-link" isRequired>
                    <FormLabel>Meeting Link</FormLabel>
                    <Input
                      type="url"
                      placeholder="Zoom, Google Meet, Teams or others"
                      onChange={(e) => (
                        setZoomLink(e.target.value), setSaveDisabled(false)
                      )}
                      value={zoomLink}
                    />

                    <FormHelperText align="left" size="10px">
                      For Zoom - login to web portal, click Profile and
                      copy-paste your Personal Meeting ID link here
                    </FormHelperText>
                  </FormControl>
                  <FormControl id="linkedIn-link" isRequired>
                    <FormLabel>LinkedIn Profile</FormLabel>
                    <Input
                      type="url"
                      onChange={(e) => (
                        setLinkedInLink(e.target.value), setSaveDisabled(false)
                      )}
                      value={linkedInLink}
                    />
                  </FormControl>
                  <FormControl id="hourly-rate" isRequired>
                    <FormLabel>Hourly Rate</FormLabel>
                    <NumberInput
                      defaultValue={hourlyRate}
                      min={0}
                      keepWithinRange={false}
                      clampValueOnBlur={false}
                      onChange={(e) => (
                        setHourlyRate(e), setSaveDisabled(false)
                      )}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    {/* <CurrencyInput
                    onChange={(e) => (
                      setBio(e.target.value), setSaveDisabled(false)
                    )}
                    value="34"
                  /> */}
                  </FormControl>
                  <FormControl id="profileURL" isRequired>
                    <FormLabel>Username</FormLabel>
                    <InputGroup>
                      <Input
                        value={userName}
                        style={{ textTransform: "lowercase" }}
                        onChange={(e) => (
                          setUserName(() => {
                            const newUsername = e.target.value.replace(/[^\w\s]/gi, "").toLocaleLowerCase()
                            setProfileURL(`personalinsights.co/u/${newUsername}`);
                            return newUsername;
                          }),
                          setSaveDisabled(false)
                        )}
                        onKeyPress={(e) => handleSpaceKeyPress(e)}
                      />
                      <Box hidden={true}>
                        <InputRightElement
                          children={<FiCheck color="green.500" />}
                        />
                      </Box>
                    </InputGroup>
                  </FormControl>
                </Stack>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box
                boxShadow="xl"
                rounded="lg"
                bg="white"
                borderWidth="1px"
                borderRadius="lg"
                // borderColor="#A0AEC0"
                overflow="hidden"
                p={6}
              >
                <Heading
                  align="center"
                  fontWeight={400}
                  pb={4}
                  as="h5"
                  size="sm"
                >
                  Toggle the day & then choose the start & end times you'll be
                  available
                </Heading>
                <div>
                  {days.map((day, index) => (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                      p={3}
                      margin="auto"
                    >
                      <SimpleGrid columns={3} spacing="20px">
                        <Stack direction="row">
                          <Switch
                            colorScheme="red"
                            isChecked={availability[day]["isActive"]}
                            onChange={(e) =>
                              handleDateSelection(e.target.checked, day)
                            }
                          ></Switch>
                          <Text>{day}</Text>
                        </Stack>
                        <Select
                          variant="filled"
                          bg="gray.200"
                          placeholder=" "
                          isDisabled={!availability[day]["isActive"]}
                          value={availability[day]["start"]}
                          onChange={(e) =>
                            handleTimeSeleciton(e.target, day, "start")
                          }
                        >
                          {timeOptions.map((time) => {
                            return (
                              <option key={time} value={time} id="start">
                                {time}
                              </option>
                            );
                          })}
                          ;
                        </Select>
                        {/* console.log(day) */}
                        <Select
                          placeholder=" "
                          variant="filled"
                          bg="gray.200"
                          isDisabled={!availability[day]["isActive"]}
                          value={availability[day]["end"]}
                          onChange={(e) =>
                            handleTimeSeleciton(e.target, day, "end")
                          }
                        >
                          {timeOptions.map((time) => {
                            return (
                              <option key={time} value={time} id="end">
                                {time}
                              </option>
                            );
                          })}
                        </Select>
                      </SimpleGrid>
                      <Box py="20px">
                        <Divider />
                      </Box>
                    </Box>
                  ))}
                </div>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <Box p="20px">
          <Center>
            <Button
              type="submit"
              width="200px"
              colorScheme="red"
              isDisabled={saveDisabled}
              onClick={handleSubmit}
            >
              Save
            </Button>{" "}
          </Center>
        </Box>
      </Container>
    </Flex>
  );
}

export default Account;
