import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
  Link,
  Text,
} from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { auth, db } from "../../config/firebase";
import { signUp, selectUser } from "../../features/userSlice";
import { handleSignUp } from "./authActions";
import { Redirect } from "react-router-dom";

function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const currentUser = useSelector(selectUser);

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // handleSignUp({
    //   email: email,
    //   password: password,
    //   firstName: firstName,
    //   lastName: lastName,
    // });
    try {
      const user = await auth.createUserWithEmailAndPassword(email, password);
      const userDetails = await db
        .collection("users")
        .doc(user.user.uid)
        .set({
          displayName: firstName + " " + lastName,
          email: email,
          firstName: firstName,
          lastName: lastName,
          initials: firstName[0] + lastName[0],
          zoomLink: "",
          linkedInLink: "",
          bio: "",
          hourlyRate: 0,
          createdAt: new Date(),
          availability: {
            Mon: {
              isActive: false,
              start: "",
              end: "",
            },
            Tue: {
              isActive: false,
              start: "",
              end: "",
            },
            Wed: {
              isActive: false,
              start: "",
              end: "",
            },
            Thu: {
              isActive: false,
              start: "",
              end: "",
            },
            Fri: {
              isActive: false,
              start: "",
              end: "",
            },
            Sat: {
              isActive: false,
              start: "",
              end: "",
            },
            Sun: {
              isActive: false,
              start: "",
              end: "",
            },
          },
        });
      dispatch(
        signUp({
          uid: user.user.uid,
          displayName: firstName + " " + lastName,
          email: email,
          firstName: firstName,
          lastName: lastName,
          initials: firstName[0] + lastName[0],
          zoomLink: "",
          bio: "",
          hourlyRate: "",
        })
      );
    } catch (error) {
      console.log(error);
      alert(error.message);
    }

    // auth
    //   .createUserWithEmailAndPassword(email, password)
    //   .then((user) => {
    //     db.collection("users")
    //       .doc(user.user.uid)
    //       .set({
    //         displayName: firstName + " " + lastName,
    //         email: email,
    //         firstName: firstName,
    //         lastName: lastName,
    //         initials: firstName[0] + lastName[0],
    //       })
    //       .then(() => {
    //         dispatch(
    //           logIn({
    //             uid: user.user.uid,
    //             displayName: firstName + " " + lastName,
    //             email: email,
    //             firstName: firstName,
    //             lastName: lastName,
    //             initials: firstName[0] + lastName[0],
    //           })
    //         );
    //       });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     alert(error.message);
    //   });
  };
  if (currentUser) {
    return <Redirect to="/account" />;
  } else {
    return (
      <form onSubmit={handleSubmit}>
        <Flex
          align="center"
          justify="center"
          height={window.innerHeight - 64}
          p={6}
        >
          <Box
            boxShadow="xl"
            rounded="lg"
            bg="white"
            w="md"
            borderWidth="1px"
            borderRadius="lg"
            // borderColor="#A0AEC0"
            overflow="hidden"
            p={6}
          >
            <Stack spacing={4}>
              <Heading align="center" pb={4} as="h2" size="xl">
                Sign up{" "}
              </Heading>
              {/* <Button
                variant="outline"
                colorScheme="gray"
                borderColor="black"
                rightIcon={<FcGoogle />}
              >
                Sign up with
              </Button>
              <Text align="center" color="gray.500">
                or
              </Text> */}

              <FormControl isRequired>
                <Input
                  placeholder="first name"
                  onChange={(e) => setFirstName(e.currentTarget.value)}
                />
              </FormControl>

              <FormControl isRequired>
                <Input
                  placeholder="last name"
                  onChange={(e) => setLastName(e.currentTarget.value)}
                />
              </FormControl>

              <FormControl isRequired>
                <Input
                  type="email"
                  placeholder="email"
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
              </FormControl>

              <FormControl isRequired>
                <Input
                  type="password"
                  placeholder="password"
                  onChange={(e) => setPassword(e.currentTarget.value)}
                />
              </FormControl>

              <Button type="submit" colorScheme="red" color="white">
                Sign up{" "}
              </Button>
              <Text fontSize="sm" align="center">
                Already a user?{" "}
                <Link color="red.600" href="/login">
                  Log in here
                </Link>
              </Text>
            </Stack>
          </Box>
        </Flex>
      </form>
    );
  }
}

export default SignUp;
