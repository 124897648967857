import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
  Link,
  Center,
} from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import { handleLogin } from "./authActions";
import { Redirect } from "react-router-dom";
import { logIn, selectUser } from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { auth, db } from "../../config/firebase";

function LogIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const toast = useToast();
  const currentUser = useSelector(selectUser);
  // console.log(currentUser);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //     console.log("handlesumbit for login");
    //     handleLogin({
    //       email: email,
    //       password: password,
    //     }).then(
    // dispatch(logIn())
    //     )
    // console.log("button clicked");
    try {
      const user = await auth.signInWithEmailAndPassword(email, password);

      console.log(user);
      const userDetails = await db.collection("users").doc(user.user.uid).get();

      const userData = userDetails.data();
      // console.log("userAuth", userData);

      dispatch(
        logIn({
          uid: user.user.uid,
          displayName: userData.displayName,
          email: userData.email,
          firstName: userData.firstName,
          lastName: userData.lastName,
          initials: userData.initials,
          bio: userData.bio,
          zoomLink: userData.zoomLink,
          hourlyRate: userData.hourlyRate,
          availability: userData.availability,
          profileImageURL: userData.profileImageURL,
          linkedInLink: userData.linkedInLink,
          userName: userData.username,
          areasOfExpertise: userData.areasOfExpertise,
        })
      );
      // console.log(userDetails);
      // dispatch(
      //   logIn({
      //     uid: user.user.uid,
      //     displayName: userDetails.displayName,
      //     email: userDetails.email,
      //     firstName: userDetails.firstName,
      //     lastName: userDetails.lastName,
      //     initials: userDetails.initials,
      //   })
      // );
    } catch (error) {
      // console.log(error);
      // alert(error.message);
      toast({
        title: "Login Error",
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }

    // <Redirect to="/account" />;
  };

  if (currentUser) {
    return <Redirect to="/account" />;
  } else {
    return (
      <form onSubmit={handleSubmit}>
        <Flex
          width="full"
          align="center"
          justifyContent="center"
          height={window.innerHeight - 64}
          p={6}
        >
          <Box
            boxShadow="xl"
            rounded="lg"
            bg="white"
            maxW="md"
            borderWidth="1px"
            borderRadius="lg"
            // borderColor="#A0AEC0"
            overflow="hidden"
            p={6}
          >
            <Stack spacing={4}>
              <Heading pb={4} as="h2" size="4xl" align="left">
                Hello, Friend!
              </Heading>
              <FormControl id="email" isRequired>
                <Input
                  type="email"
                  placeholder="email"
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
              </FormControl>

              <FormControl id="password" isRequired>
                <Input
                  type="password"
                  placeholder="password"
                  onChange={(e) => setPassword(e.currentTarget.value)}
                />
              </FormControl>
              <Button type="submit" colorScheme="red" color="white">
                Log In
              </Button>
              {/* <Text align="center" color="gray.500">
                or
              </Text>

              <Button
                variant="outline"
                colorScheme="gray"
                borderColor="black"
                rightIcon={<FcGoogle />}
              >
                Log in with
              </Button> */}
              <Text fontSize="sm" align="center">
                Not a user yet?{" "}
                <Link color="red.600" href="/signup">
                  Sign up here
                </Link>
              </Text>
            </Stack>
          </Box>
        </Flex>
      </form>
    );
  }
}

export default LogIn;
