import {
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { db } from "../config/firebase";
import queryString from "query-string";

function ThankYou(props) {
  // Old way of trying to extract booking id from url params
  // const query = new URLSearchParams(props.location.search);
  // const token = query.get("bookingID");
  // console.log(token, props.location.search);

  // Extracting the bookingID from url params
  const query = queryString.parse(props.location.search);
  const token = query.bookingID;
  // console.log(token, query, props.location.search);

  // http://localhost:3000/thanks?success=true?bookingID=[object%20Object]

  // Retrieve the booking id from url params (only in success redirects from Stripe) then if it's there update the booking as complted
  if (token) {
    db.collection("bookings").doc(token).update({ isCompleted: true });
  }

  return (
    <Flex
      py="20px"
      align="center"
      justify="center"
      height={window.innerHeight - 64}
    >
      <Container>
        <Center>
          <Heading pb={4} as="h2" size="xl">
            Thank you for booking!
          </Heading>
        </Center>
        <VStack spacing={4}>
          <Text>Check your email for a confirmation</Text>
          <Link color="red.600" href="/signup">
            <Button type="submit" colorScheme="red">
              Create Your Own Profile
            </Button>
          </Link>
        </VStack>
      </Container>
    </Flex>
  );
}

export default ThankYou;
