import {
  Box,
  Heading,
  HStack,
  Image,
  Flex,
  Spacer,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import "./Nav.css";
import NavOptions from "./NavOptions";
import { FcIdea } from "react-icons/fc";
import lightBulb from "../../img/lightbulb.png";

function Navbar() {
  // console.log(window.innerHeight);
  return (
    <Box bg="transparent" /* background-color: #f8f8fa; */>
      <Flex px={8} py={4} alignContent="flex-start" alignItems="center">
        <Box>
          <a href="/">
            <Heading size="md">Insights</Heading>
          </a>
        </Box>
        <Spacer />
        <NavOptions />
      </Flex>
    </Box>

    // <div className="header">
    //   <div className="header__left">
    //     <div className="header__title">
    //       <Box p="2">
    //         <a href="/">
    //           <HStack spacing="10px">
    //             {/* <Image src={lightBulb} alt="ligth bulb" boxSize="20px" /> */}

    //             {/* <img src={lightBulb} alt="ligth bulb" /> */}
    //             {/* <FcIdea size="md" /> */}
    //             <Heading size="md">Insights</Heading>
    //           </HStack>
    //         </a>
    //       </Box>
    //     </div>
    //     {/* <img
    //       src="https://upload.wikimedia.org/wikipedia/commons/4/45/Notion_app_logo.png"
    //       alt="Consulting Logo"
    //     /> */}
    //   </div>
    //   <div className="header__right">
    //     <NavOptions />
    //   </div>
    // </div>
  );
}

export default Navbar;
