import {
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";

function OrderCancelled() {
  return (
    <Flex
      py="20px"
      align="center"
      justify="center"
      height={window.innerHeight - 64}
    >
      <Container>
        <Center>
          <Heading pb={4} as="h2" size="xl">
            Order Cancelled{" "}
          </Heading>
        </Center>
        <VStack spacing={4}>
          <Text>Your booking didn't go through.</Text>
          <Link color="red.600" href="/">
            <Button type="submit" colorScheme="red">
              Go To Homepage
            </Button>
          </Link>
        </VStack>
      </Container>
    </Flex>
  );
}

export default OrderCancelled;
