import { Box, Heading, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ProfileCard from "./ProfileCard";
import { db } from "../../config/firebase";

const profilesPlaceholder = [
  {
    displayName: "Daniel Adonai",
    hourlyRate: "0",
    profileURL: "https://personalinsights.co/u/danieladonai",
    bio:
      "Co-founder of Insights (this) & a couple ecommerce busineses that I sold to pay off my loans. Let's talk about up how to start a company and how to scale and automate it.",
    profileImageURL:
      "https://firebasestorage.googleapis.com/v0/b/consulting-app-e1463.appspot.com/o/profileImages%2FDaniel%20Adonai's%20Image.jpeg?alt=media&token=517cd265-4cc1-466d-854e-503d4eed6381",
    areasOfExpertise: [
      "Product",
      "Engineering",
      "Ecommerce",
      "Startups",
      "Automation",
    ],
  },
  {
    displayName: "Salima Fakirani",
    hourlyRate: "75",
    profileURL: "https://personalinsights.co/u/salimafakirani",
    bio:
      "I’m a business and law school graduate interested in helping others get into (their top choice) law school!",
    profileImageURL:
      "https://firebasestorage.googleapis.com/v0/b/consulting-app-e1463.appspot.com/o/profileImages%2FSalima's%20Image.jpeg?alt=media&token=b8d35516-fe7f-46ac-bcc8-35484d60cce3",
    areasOfExpertise: ["LSAT", "Corporate Law", "Bay Street Law", "Law School"],
  },

  {
    displayName: "Rotimi Fadiya",
    hourlyRate: "60",
    profileURL: "https://personalinsights.co/u/rotimifadiya",
    bio:
      "Electrical & Biomedical Engineer who founded a medical device startup. Starting as my engineering capstone project, we were building “The sKan” - a melanoma detection device. This device went on to win the International James Dyson Design Award in 2017; a competition contested in over 20 countries worldwide. Since graduating, I have also been working at the Consulting firm Accenture for the past 3 years leading Fortune 500 companies in Cloud transformations. Most recently, I was accepted to The Wharton School in Philadelphia to pursue my MBA and will be matriculating this fall.",
    profileImageURL:
      "https://firebasestorage.googleapis.com/v0/b/consulting-app-e1463.appspot.com/o/profileImages%2FRotimi's%20Picture.jpeg?alt=media&token=e1fbbe63-85e5-40aa-b79d-5eb52852b669",
    areasOfExpertise: [
      "Management Consulting",
      "Wharton MBA",
      "Engingeering",
      "eCommerce",
    ],
  },
  {
    displayName: "Shishir “S” Pande",
    hourlyRate: "250",
    profileURL: "https://personalinsights.co/u/spande",
    bio:
      "Virtual reality, Augmented reality, Business strategy, Partnerships, B2B Sales",
    profileImageURL:
      "https://firebasestorage.googleapis.com/v0/b/consulting-app-e1463.appspot.com/o/profileImages%2Fed679267-902e-4952-8cbb-03704f18fd8c?alt=media&token=7ccdf254-6522-4727-8ed9-ff2da8569fd8",
    areasOfExpertise: [
      "Virtual Reality",
      "Augmented Reality",
      "Startups",
      "Engineering",
    ],
  },
  {
    displayName: "Marwan Dajani",
    hourlyRate: "85",
    profileURL: "https://personalinsights.co/u/marwandajani",
    bio:
      "I am a niche small business consultant for over 5 years. Growth Product Manager for a Saas company. 10 years of experience in electronic music production with tracks signed on multiple record labels. ",
    profileImageURL:
      "https://firebasestorage.googleapis.com/v0/b/consulting-app-e1463.appspot.com/o/profileImages%2FMarwan%20Dajani's%20Photo.jpeg?alt=media&token=53233c9a-379f-42c2-9c11-58dd9e64ffb2",
    areasOfExpertise: [
      "Tech Consulting",
      "Strategy",
      "Music Production",
      "Mixing & Mastering",
    ],
  },
  {
    displayName: "Shuvra Rahman",
    hourlyRate: "20",
    profileURL: "https://personalinsights.co/u/shuvrarahman",
    bio:
      "Hi, I'm Shuvra, I've got experience in tech start-ups, e-commerce, scaling customer experience teams.",
    profileImageURL:
      "https://firebasestorage.googleapis.com/v0/b/consulting-app-e1463.appspot.com/o/profileImages%2Fc0156a42-923e-496e-8b47-b0e23638b26d?alt=media&token=14029a83-d140-4860-ab08-a2d0a3d6ce59",
    areasOfExpertise: ["Sales", "Customer Success", "Ecommerce", "Startups"],
  },
];

// console.log("first value of profiles array", profiles[0]);
function ProfileShowcase(props) {
  const { caption } = props;
  const [profiles, setProfiles] = useState(profilesPlaceholder);

  useEffect(() => {
    // Get a list of featured profiles with a photo
    const getFeaturedProfilesList = async () => {
      const featuredProfiles = await db
      .collection("users")
      .where("isFeatured", "==", true)
      .get();

    const allProfiles = [];
    featuredProfiles.forEach((doc) => {
      allProfiles.push({ id: doc.id, ...doc.data() });
    });
    setProfiles(allProfiles);
    }
    getFeaturedProfilesList()
  }, []);

  return (
    <div id="mentor-showcase">
      <Box p={10}>
        <Heading
          as="h2"
          // color="gray.600"
          fontSize={{ base: "4xl", sm: "4xl", md: "4xl" }}
          py="40px"
        >
          {caption}
        </Heading>
        <Box>
          <SimpleGrid
            columns={{ base: 1, sm: 1, md: 2, lg: 3 }}
            spacing={10}
          >
            {profilesPlaceholder.map((profile, index) => {
              return (
                <div key={index}>
                  <ProfileCard
                    displayName={profile.displayName}
                    hourlyRate={profile.hourlyRate}
                    profileURL={profile.profileURL}
                    profileImageURL={profile.profileImageURL}
                    bio={profile.bio}
                    areasOfExpertise={profile.areasOfExpertise}
                  />
                </div>
              );
            })}
          </SimpleGrid>
        </Box>
      </Box>
    </div>
  );
}

export default ProfileShowcase;
