import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  Center,
  Heading,
} from "@chakra-ui/react";
import { FiShare, FiCreditCard } from "react-icons/fi";
import { BsLightning } from "react-icons/bs";
import { BiDollar } from "react-icons/bi";

// Taken from https://chakra-templates.dev/

const Feature = ({ title, text, icon }) => {
  return (
    <Stack>
      <Flex
        // w={16}
        // h={16}
        align={"center"}
        justify={"center"}
        color={"red"}
        // rounded={"full"}
        // bg={"gray.50"}
        mb={1}
      >
        {icon}
      </Flex>
      {/* <Center bg="gray.50">{icon}</Center> */}
      <Text fontWeight={600}>{title}</Text>
      <Text color={"gray.600"}>{text}</Text>
    </Stack>
  );
};

export default function HowItWorks() {
  return (
    <Box p={10}>
      <Heading
        as="h2"
        // color="gray.600"
        fontSize={{ base: "4xl", sm: "4xl", md: "4xl" }}
        py="40px"
      >
        How It Works
      </Heading>
      <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={10}>
        <Feature
          icon={<Icon as={BsLightning} w={8} h={8} />}
          title={"Fast Setup"}
          text={
            "Easily connect your profile to LinkedIn & setup your availabilities"
          }
        />
        <Feature
          icon={<Icon as={FiShare} w={8} h={8} />}
          title={"Share Your Profile"}
          text={
            "You get a dedicated profile you can share with people to book time with you"
          }
        />
        <Feature
          icon={<Icon as={BiDollar} w={8} h={8} />}
          title={"Commission-free"}
          text={
            "Yup, you get to keep all your proceeds minus the credit card fee"
          }
        />
        <Feature
          icon={<Icon as={FiCreditCard} w={8} h={8} />}
          title={"Fast Payments"}
          text={"Quick & simple e-transfers as soon as your meetings are done"}
        />
      </SimpleGrid>
    </Box>
  );
}
