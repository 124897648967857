import {
  Box,
  Container,
  Flex,
  Center,
  FormControl,
  Heading,
  Input,
  VStack,
  Textarea,
  Button,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";

function Checkout() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleBooking = (e) => {};
  return (
    <Flex py="20px" align="center" justify="center" height="100vh">
      <Container>
        <Box pb="20px">
          <Center>
            <Heading pb={4} as="h3" size="md">
              Booking with Danie Adonai{" "}
            </Heading>
          </Center>
        </Box>

        <VStack spacing={4}>
          <Text>Date Time</Text>
          <FormControl isRequired>
            <Input
              type="email"
              placeholder="email"
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <Textarea
              type="text"
              placeholder="summary of what you're looking for"
              onChange={(e) => setMessage(e.currentTarget.value)}
            />
          </FormControl>
          <Button
            type="submit"
            width="200px"
            colorScheme="red"
            onClick={(e) => {
              handleBooking(e);
            }}
          >
            Book now
          </Button>
        </VStack>
      </Container>
    </Flex>
  );
}

export default Checkout;
