import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  Center,
  Heading,
} from "@chakra-ui/react";
import { FiShare, FiCreditCard } from "react-icons/fi";
import { BsPeople, BsQuestionCircle } from "react-icons/bs";
import { BiCalendarCheck, BiDollar, BiHappyAlt } from "react-icons/bi";

// Taken from https://chakra-templates.dev/

const Feature = ({ title, text, icon }) => {
  return (
    <Stack>
      <Flex
        // w={16}
        // h={16}
        align={"center"}
        justify={"center"}
        color={"red"}
        // rounded={"full"}
        // bg={"gray.50"}
        mb={1}
      >
        {icon}
      </Flex>
      {/* <Center bg="gray.50">{icon}</Center> */}
      <Text fontWeight={600}>{title}</Text>
      <Text color={"gray.600"}>{text}</Text>
    </Stack>
  );
};

export default function HowItWorks() {
  return (
    <Box p={10}>
      <Heading
        as="h2"
        // color="gray.600"
        fontSize={{ base: "4xl", sm: "4xl", md: "4xl" }}
        py="40px"
      >
        How It Works
      </Heading>
      <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={10}>
        <Feature
          icon={<Icon as={BsQuestionCircle} w={8} h={8} />}
          title={"Got Questions?"}
          text={
            "Figure out what career questions you have & want to learn about"
          }
        />
        <Feature
          icon={<Icon as={BsPeople} w={8} h={8} />}
          title={"Find A Mentor"}
          text={"Search below and find the one or many that can help you out"}
        />
        <Feature
          icon={<Icon as={BiCalendarCheck} w={8} h={8} />}
          title={"Book Time"}
          text={
            "Check the mentor's schedule, then book & pay (if applicable) for a time slot to meet"
          }
        />
        <Feature
          icon={<Icon as={BiHappyAlt} w={8} h={8} />}
          title={"Get Answers!"}
          text={
            "Get uninterrupted time to ask all your questions and get personalized answers"
          }
        />
        {/* <Feature
          icon={<Icon as={FiCreditCard} w={8} h={8} />}
          title={"Fast Payments"}
          text={"Quick & simple e-transfers as soon as your meetings are done"}
        /> */}
      </SimpleGrid>
    </Box>
  );
}
