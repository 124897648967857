import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    brandLight: {
      50: "",
      100: "",
      200: "",
      300: "",
      400: "",
      500: "",
      600: "",
      700: "#2a69ac",
      800: "#f25757",
      900: "#34344a",
    },
    brandDark: {
      50: "",
      100: "",
      200: "",
      300: "",
      400: "",
      500: "",
      600: "",
      700: "#2a69ac",
      800: "#f25757",
      900: "#34344a",
    },
  },
  // fonts: {
  //   body: "system-ui, sans-serif",
  //   heading: "Apercu, serif",
  //   mono: "Menlo, monospace",
  // },
});

export { theme };
